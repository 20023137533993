<template>
  <ReportsTable
    :reports="reports"
    :loading="loading"
  />
</template>

<script>
import ReportsTable from './ReportsTable.vue';

export default {
  name: 'ReportsTableContainer',

  components: {
    ReportsTable,
  },

  inject: ['Names'],

  data() {
    return {
      reports: [],
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$di.api.ReportServiceApi
        .reportsGet()
        .then(({ reports }) => {
          this.reports = reports;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
